import React from "react";
import { MyCard as Card } from "./card";

export class Cards extends React.Component {
	render() {
		const { cards } = this.props;
		return cards.map(({ name, price, ingredients, description }) => {
			return (
				<Card
					key={name}
					name={name}
					price={price}
					ingredients={ingredients}
					description={description}
				/>
			);
		});
	}
}
