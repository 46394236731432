import React from 'react';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import {FoodApp} from './apps/food-app';
import './App.css';

function App() {
  return (
    <Router>
      <div><Link to ='/food-app'>Food App</Link></div>
      <Route path='/food-app'><FoodApp/></Route>
    </Router>
  );
}

export default App;
