import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import axios from "axios";

const useStyles = (theme) => ({
	root: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "50ch",
		},
	},
});

class MyFormInner extends React.Component {
	constructor() {
		super();
		this.state = { name: "", price: 0, description: "", ingredients: "" };

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleReset = this.handleReset.bind(this);
	}

	render() {
		const { classes } = this.props;
		return (
			<form
				className={classes.root}
				onReset={this.handleReset}
				onSubmit={this.handleSubmit}
				noValidate
				autoComplete="off"
			>
				<TextField
					id="name"
					label="Name"
					value={this.state.name}
					onChange={this.handleChange}
				/>
				<TextField
					id="price"
					label="Price"
					value={this.state.price}
					onChange={this.handleChange}
				/>
				<TextField
					id="description"
					label="Description"
					value={this.state.description}
					onChange={this.handleChange}
				/>
				<TextField
					id="ingredients"
					label="Ingredients"
					value={this.state.ingredients}
					onChange={this.handleChange}
				/>
				{this.renderButtons()}
			</form>
		);
	}

	handleSubmit(event) {
		const { name, price, description, ingredients } = this.state;
		event.preventDefault();
		const corsProxy = "https://cors-anywhere.herokuapp.com";
		const proxy =
			"http://myexpressserver-env.eba-gsti4ndw.us-east-2.elasticbeanstalk.com/";

		axios
			.post(`${corsProxy}/${proxy}/addDb`, {
				name,
				price,
				description,
				ingredients,
			})
			.then(() => console.log("Successfully sent to database"))
			.catch((error) => {
				alert("something went wrong :(");
				console.log(error);
			});
		alert("Submitted!");
		this.handleReset();
	}

	handleReset() {
		this.setState({ name: "", price: 0, description: "", ingredients: "" });
	}

	handleChange(event) {
		this.setState({ [event.target.id]: event.target.value });
	}

	renderButtons = () => {
		const buttons = [
			<Button key="submit-button" type="submit">
				Submit
			</Button>,
			<Button key="reset-button" type="reset">
				Reset
			</Button>,
		];
		return buttons;
	};
}

export const MyForm = withStyles(useStyles)(MyFormInner);
