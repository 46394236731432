import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardActions, Button } from "@material-ui/core";

const useStyles = (theme) => ({
	root: {
		maxWidth: 345,
	},
});

class CardInner extends React.Component {
	renderButtons = () => {
		const buttons = [
			<Button key="more">More</Button>,
			<Button key="Add to cart">Add to cart</Button>,
		];
		return buttons;
	};

	render() {
		const { classes, name, price, ingredients, description } = this.props;
		return (
			<Card className={classes.root}>
				<CardContent>
					<h2>{name}</h2>
					<h3>Price: {price}</h3>
					<p>Description: {description}</p>
					<p>Ingredients: {ingredients}</p>
				</CardContent>
				<CardActions>{this.renderButtons()}</CardActions>
			</Card>
		);
	}
}

export const MyCard = withStyles(useStyles)(CardInner);
