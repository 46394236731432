import React from "react";
import { TextBox } from "../../../components/components-text-box/";
import { Cards } from "../../../components/cards/";
import { Form } from "../../../components/form/";
import axios from "axios";

export class FoodApp extends React.Component {
	constructor() {
		super();
		this.state = { cards: [] };
	}

	componentDidMount() {
		const corsProxy = "https://cors-anywhere.herokuapp.com";
		const proxy =
			"http://myexpressserver-env.eba-gsti4ndw.us-east-2.elasticbeanstalk.com/";
		axios
			.get(`${corsProxy}/${proxy}/getDb`)
			.then(({ data }) => this.setState({ cards: data }));
	}

	render() {
		const { cards } = this.state;
		return (
			<div>
				<div>{this.greeting()}</div>
				<TextBox />
				<Form />
				<Cards cards={cards} />
			</div>
		);
	}

	greeting = () => {
		return "Hi, this is my food page";
	};
}
