import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import axios from "axios";

const useStyles = (theme) => ({
	root: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
			width: "50ch",
		},
	},
});

class TextBoxInner extends React.Component {
	constructor() {
		super();
		this.state = { value: "" };

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleReset = this.handleReset.bind(this);
	}

	handleSubmit(event) {
		event.preventDefault();
		const corsProxy = "https://cors-anywhere.herokuapp.com";
		const proxy =
			"http://myexpressserver-env.eba-gsti4ndw.us-east-2.elasticbeanstalk.com/";
		axios
			.post(`${corsProxy}/${proxy}/form`, { inquiry: this.state.value })
			.catch((error) => {
				alert(`something went wrong :(`);
				console.log(error);
			});
		this.setState({ value: "" });
		alert("Inquiry sent! Have a nice day!");
	}

	handleChange(event) {
		this.setState({ value: event.target.value });
	}

	handleReset() {
		this.setState({ value: "" });
	}

	renderButtons = () => {
		const buttons = [
			<Button key="submit-button" type="submit">
				Submit
			</Button>,
			<Button key="reset-button" type="reset">
				Reset
			</Button>,
		];
		return buttons;
	};

	render() {
		const { classes } = this.props;
		return (
			<form
				className={classes.root}
				onReset={this.handleReset}
				onSubmit={this.handleSubmit}
				noValidate
				autoComplete="off"
			>
				<TextField
					id="my-text-box"
					label="My Inquiry"
					multiline
					value={this.state.value}
					onChange={this.handleChange}
				/>
				{this.renderButtons()}
			</form>
		);
	}
}

export const TextBox = withStyles(useStyles)(TextBoxInner);
